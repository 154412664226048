import PropTypes from "prop-types"
import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Image from "../Image"
import Text from "../Text"

class HeadingBlurbMedia extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var headingLevel
    var heading

    if (this.props.headingLevel) {
      headingLevel = this.props.headingLevel
    } else {
      headingLevel = "h3"
    }

    switch (headingLevel) {
      case "h1":
        heading = (
          <Text
            as="h1"
            className={this.props.headingLook}
            style={{ marginBottom: this.props.paragraph ? "" : "2rem" }}
            text={this.props.heading}
          />
        )
        break
      case "h2":
        heading = (
          <Text
            as="h2"
            className={this.props.headingLook}
            style={{
              marginBottom: this.props.paragraph ? "" : "2rem",
              marginTop:
                this.props.customStyle === "Dental Implants" ? "7px" : "3rem"
            }}
            text={this.props.heading}
          />
        )
        break
      case "h3":
        heading = (
          <Text
            as="h3"
            className={this.props.headingLook + " has-text-centered-mobile"}
            style={{ marginBottom: this.props.paragraph ? "" : "2rem" }}
            text={this.props.heading}
          />
        )
        break
      case "h4":
        heading = (
          <Text
            as="h4"
            className={this.props.headingLook}
            style={{ marginBottom: this.props.paragraph ? "" : "2rem" }}
            text={this.props.heading}
          />
        )
        break
      case "h5":
        heading = (
          <Text
            as="h5"
            className={this.props.headingLook}
            style={{ marginBottom: this.props.paragraph ? "" : "2rem" }}
            text={this.props.heading}
          />
        )
        break
      case "h6":
        heading = (
          <Text
            as="h6"
            className={this.props.headingLook}
            style={{ marginBottom: this.props.paragraph ? "" : "2rem" }}
            text={this.props.heading}
          />
        )
        break
    }

    return (
      <section className="section heading-blurb-video">
        <div className="columns">
          <div className={`column is-${this.props.headingSideColumnIs}`}></div>
          <div className="column">{this.props.heading && heading}</div>
          <div className={`column is-${this.props.headingSideColumnIs}`}></div>
        </div>
        <div className="columns">
          {!this.props.isMediaFullWidth && (
            <div className={`column is-${this.props.videoSideColumnIs}`}></div>
          )}
          <div className="column">
            <Image publicId={this.props.youtubePhotoPublicId} />
            {/* <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${this.props.youtube}`}
              controls={true}
              playing={true}
              overVideo={false}
              hasWatchVideo={true}
              buttonClass="contained"
            >
            </TheaterVideo> */}
          </div>
          {!this.props.isMediaFullWidth && (
            <div className={`column is-${this.props.videoSideColumnIs}`}></div>
          )}
        </div>

        <div className="columns" style={{ marginTop: "1.5rem" }}>
          {!this.props.isMediaFullWidth && (
            <div className={`column is-${this.props.videoSideColumnIs}`}></div>
          )}
          <div className="column">
            <Text className="mb-2-mobile small" text={this.props.paragraph} />
          </div>
          <div className="column is-1"></div>
          <div className="column is-narrow">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${this.props.youtube}`}
              controls={true}
              playing={true}
              overVideo={false}
              hasWatchVideo={true}
              buttonClass="contained mt-0"
              style={{ marginTop: 0 }}></TheaterVideo>
          </div>
          {!this.props.isMediaFullWidth && (
            <div className={`column is-${this.props.videoSideColumnIs}`}></div>
          )}
        </div>
      </section>
    )
  }
}

HeadingBlurbMedia.propTypes = {
  headingSideColumnIs: PropTypes.number,
  isMediaFullWidth: PropTypes.bool,
  videoSideColumnIs: PropTypes.number,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  youtube: PropTypes.string,
  youtubePhotoPublicId: PropTypes.string
}

export default HeadingBlurbMedia
