import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Dialog } from "@blueprintjs/core"

import TheaterVideo from "@components/Theater/TheaterVideo"
import CloseIcon from "../CloseIcon"

import Text from "../Text"

export default function Modal(props) {
  const data = useStaticQuery(graphql`
    query anesQuery {
      allUniquePagesJson(
        filter: { title: { eq: "anesthesia-and-sedation-dentistry/" } }
      ) {
        nodes {
          anesthesiaTypes {
            buttons {
              buttonText
              heading
              blurb
              youtube
              href
            }
            blurb
            heading
          }
        }
      }
    }
  `)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [activeText, setActiveText] = useState({})

  function toggleDialog(activeText) {
    setDialogOpen(!dialogOpen)
    setActiveText(activeText)
  }

  // if this is broken, update filter url in graphql query above
  let anesthesiaTypes = data.allUniquePagesJson.nodes[0].anesthesiaTypes

  let modalButtons = anesthesiaTypes.buttons

  return (
    <>
      <Dialog
        canOutsideClickClose={true}
        enforceFocus={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        isOpen={dialogOpen}
        usePortal={true}
        onClose={toggleDialog}
        className="anesthesia-dialog">
        <div className="has-text-centered dialog-content">
          <CloseIcon onClick={toggleDialog} />
          <h3>{activeText.modalHeading}</h3>
          <p className="large">{activeText.modalBlurb}</p>
          {activeText.youtube && (
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${activeText.youtube}`}
              controls={true}
              playing={true}
              onlyButton={true}
              buttonClass="contained"
            />
          )}
          {!activeText.youtube && <div style={{ height: "80px" }}></div>}
        </div>
      </Dialog>
      <div
        className="has-text-centered color-back anesthesia-modal"
        style={{ paddingTop: "40px", paddingBottom: "50px" }}>
        <div className="columns">
          <div className="column is-5"></div>
          <div className="column">
            <h3>{anesthesiaTypes.heading}</h3>
            <Text text={anesthesiaTypes.blurb} />
          </div>
          <div className="column is-5"></div>
        </div>
        <div className="columns">
          <div className="column anesthesia-modal-buttons">
            <div className="button-group-wrapper">
              <div className="button-group is-centered">
                {modalButtons.map(modal => (
                  <React.Fragment key={modal.heading}>
                    {modal.href ? (
                      <Link to={modal.href}>
                        <a className="standard-button contained">
                          {modal.buttonText === "EXPAREL®" ? (
                            <>
                              EXPAREL<sup>®</sup>
                            </>
                          ) : (
                            modal.buttonText
                          )}
                        </a>
                      </Link>
                    ) : (
                      <a
                        className="standard-button contained"
                        onClick={() =>
                          toggleDialog({
                            modalHeading: modal.heading,
                            modalBlurb: modal.blurb,
                            youtube: modal.youtube
                          })
                        }>
                        {modal.buttonText}
                      </a>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
