import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import Image from "../Image"
import { Button, ButtonGroup } from "../Button"

function ColumnsWrapper(props) {
  return (
    <div className="columns">
      {!props.isFullWidth && (
        <div className={`column is-${props.sideColumnIs}`}></div>
      )}
      <div className="column">{props.children}</div>
      {!props.isFullWidth && (
        <div className={`column is-${props.sideColumnIs}`}></div>
      )}
    </div>
  )
}

function Heading({
  headingType,
  heading,
  paddingTop,
  paddingBottom,
  textAlign
}) {
  return (
    <Text
      as={headingType}
      text={heading}
      style={{
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        textAlign: textAlign
      }}
    />
  )
}

function SectionImage(props) {
  return <Image publicId={props.mainPhotoPublicId} />
}

function Paragraphs(props) {
  return (
    <Text
      style={{
        paddingTop: props.paddingTop,
        paddingBottom: props.paddingBottom
      }}
      text={props.textSection}
    />
  )
}

class GenericSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.genericSection) {
      var sectionColumns = this.props.genericSection.map(section => {
        switch (section.type) {
          case "heading":
            return <Heading {...section} />
          case "sectionImage":
            return <SectionImage {...section} />
          case "paragraphs":
            return <Paragraphs {...section} />
          case "buttons":
            var buttons = section.buttons.map(buttons => (
              <Button key={buttons.button.href} {...buttons.button} />
            ))
            return <ButtonGroup {...section}>{buttons}</ButtonGroup>
          case "modals":
            // return <Modal {...section} />
            return <div></div>
        }
      })
    }

    var sectionClasses = classNames({
      "body-sections section generic-section": true,
      "color-back": this.props.colorBack
    })

    return (
      <section
        className={sectionClasses}
        style={{
          paddingTop: this.props.paddingTop ? this.props.paddingTop : null,
          paddingBottom: this.props.paddingBottom
            ? this.props.paddingBottom
            : null
        }}>
        <ColumnsWrapper {...this.props}>{sectionColumns}</ColumnsWrapper>
      </section>
    )
  }
}

GenericSection.propTypes = {
  headingSideColumnIs: PropTypes.number,
  isMediaFullWidth: PropTypes.bool,
  videoSideColumnIs: PropTypes.number,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  youtube: PropTypes.string,
  youtubePhotoPublicId: PropTypes.string
}

export default GenericSection
